import React, { FunctionComponent } from "react"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "gatsby-plugin-firebase"
import { useFirebaseAuth } from "../utils/auth"
import { navigate } from "gatsby"

export const Login: FunctionComponent = () => {
  const [ user ] = useFirebaseAuth()
  let retVal = null

  const getUiConfig = (auth: any) => {
    return {
      signInFlow: 'popup',
      signInOptions: [
        auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          navigate("/")
          return false
        },
      },
    }
  }

  if (!user) {
    retVal = (<div>
      {firebase && <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()}/>}
    </div>)
  } else {
    retVal = <p>すでにログイン済みです。</p>
  }

  return retVal
}
